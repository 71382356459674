module.exports = {
	"email": "E-mail",
	"phone": "Telefon",
	"name": "Navn",
	"avatar": "Profilbillede",
	"save": "Gem",
	"saved": "Gemt",
	"saving": "Gemmer",
	"edit": "Rediger",
	"create": "Opret",
	"remove": "Slet",
	"reinvite": "Inviter igen",
	"createUser": "Opret bruger",
	"loading": "Indlæser {resource}",
	"dashboard": "Dashbord",
	"project": "Projekt | Projekter",
	"theProject": "Projektet",
	"map": "Kort",
	"yourProject": "Dit projekt | Dine projekter",
	"allProjects": "Alle projekter",
	"noProjects": "Ingen projekter",
	"residential": "Bolig | Boliger",
	"noResidentials": "Ingen boliger",
	"residentialTemplate": "Boligtype | Boligtyper",
	"createResidentialTemplate": "Opret boligtype",
	"createUnitTemplate": "",
	"user": "Bruger | Brugere",
	"settings": "Indstillinger",
	"login": "Log ind",
	"logout": "Log ud",
	"termsCondition": "Aftalevilkår",
	"privacyPolicy": "Privatlivsbeskyttelse",
	"cookiePolicy": "Cookie Policy",
	"menu": "Menu",
	"followUs": "Følg os",
	"getStarted": "Kom i gang",
	"noAccess": "Ingen adgang",
	"projectName": "Projektnavn",
	"publish": "Offentliggør",
	"publishing": "Offentliggørelse",
	"published": "Offentliggjort",
	"unpublished": "Ikke offentliggjort",
	"domain": "Domæne",
	"retry": "Prøv igen",
	"close": "Luk",
	"showDetails": "Vis detaljer",
	"hideDetails": "Skjul detaljer",
	"createLead": "Ny interessent",
	"newLead": "Ny interessent",
	"lead": "Interessent | Interessenter",
	"leadMessage": "Besked",
	"message": "Meddelelse | Meddelelser",
	"newMessage": "Ny meddelelse",
	"comment": "@:message",
	"noMessages": "Ingen meddelelser",
	"description": "Beskrivelse",
	"showMore": "Vis flere",
	"noLeads": "Ingen interessenter",
	"openhouse": "Fremvisning | Fremvisninger",
	"openhouseOnRequest": "Fremvisning efter aftale",
	"noOpenhouse": "Ingen fremvisninger",
	"createOpenhouse": "Ny fremvisning",
	"from": "Fra",
	"to": "Til",
	"date": "Dato",
	"role": "Rolle | Roller",
	"flatfinder": "Boligvælger | Boligvælgere",
	"unitSelector": "",
	"flatfinderTypeFlat": "Lejlighedsvælger | Lejlighedsvælgere",
	"flatfinderTypePlot": "Grundvælger | Grundvælgere",
	"flatfinderTypeOffice": "",
	"createFlatfinder": "Ny boligvælger",
	"createUnitSelector": "",
	"home": "Hjem",
	"statistics": "Statistik",
	"notset": "Ikke indstillet",
	"projectmanager": "Projektmedarbejder | Projektmedarbejdere",
	"customermanager": "Kundeservicemedarbejder | Kundeservicemedarbejdere",
	"complaintmanager": "",
	"search": "Søg",
	"searchFor": "Søg efter",
	"cancel": "Afbryd",
	"confirm": "Bekræft",
	"couldnotfindonquery": "Ingen resultater \"{query}\"",
	"selectx": "Vælg {x}",
	"brochure": "Salgsmateriale",
	"downloadBrochure": "Download salgsmateriale",
	"logo": "Logo",
	"logoInverted": "Logo (lys variant)",
	"favicon": "Favicon (Websideikon)",
	"gallery": "Galleri",
	"internalId": "Internt ID",
	"createProject": "Nyt projekt",
	"address": "Adresse",
	"postcode": "Postnummer",
	"city": "Sted",
	"county": "Region",
	"latitude": "Breddegrad",
	"longitude": "Længdegrad",
	"position": "Position",
	"createResidential": "Ny bolig",
	"cadastre": "Matrikel",
	"residentialnumber": "Bolignummer",
	"number": "Nummer",
	"street": "Gadeadresse",
	"floorplan": "Plantegning | Plantegninger",
	"dragImageOrDrop": "Træk billedet herhen, eller <b>tryk</b> for at uploade | Træk billederne herhen, eller <b>tryk</b> for at uploade",
	"dragFilesOrDrop": "Træk filerne {extension} herhen, eller <b>tryk</b> for at uploade",
	"dragFileOrDrop": "Træk filen {extension} herhen, eller <b>tryk</b> for at uploade",
	"price": "Pris",
	"residentialProperties": "Nøgleoplysninger",
	"propertyType": "Boligtype",
	"propertyTypeWarning": "<b>OBS!</b> Du har valgt boligtype \"{value}\", og derfor skal du tilføje værdier pr. enhed.",
	"numberOfBedrooms": "Soveværelse",
	"numberOfRooms": "Værelse",
	"numberOfBathrooms": "Bad/toilet",
	"primaryRoomArea": "Primærrum",
	"grossArea": "Bruttoareal",
	"useableArea": "Brugsareal",
	"floor": "Etage",
	"numberOfFloors": "Antal etager",
	"plotArea": "Grundareal",
	"ownershipType": "Ejerform",
	"numberOfParkingSpots": "Antal parkeringspladser",
	"energyLabel": "Energimærkning",
	"plotOwned": "Ejet grund",
	"plotGroundTax": "Ejendomsskat",
	"plotOwnershipType": "Ejerforhold (grund)",
	"communityTax": "Kommunale afgifter",
	"taxValue": "Formueværdi",
	"constructionYear": "Byggeår",
	"renovatedYear": "Renoveret år",
	"value": "Værdi",
	"add": "Tilføj",
	"custom": "Brugerdefineret",
	"yes": "Ja",
	"no": "Nej",
	"quantity": "Antal",
	"none": "Ingen",
	"bid": "Bud",
	"addBid": "Nyt bud",
	"bidUrl": "Link til budafgivelse",
	"purchaseUrl": "Link til signering af købskontrakt",
	"size": "Størrelse",
	"sale": "Salg",
	"basis": "Grundlag",
	"presentation": "Præsentation",
	"integration": "Integration | Integrationer",
	"next": "Næste",
	"prev": "Forrige",
	"cover": "Cover",
	"propertyType:detached": "Enfamiliebolig | Enfamilieboliger",
	"propertyType:flat": "Lejlighed | Lejligheder",
	"propertyType:terraced": "Rækkehus",
	"propertyType:semidetached": "Dobbelthus",
	"propertyType:plot": "Boliggrund | Boliggrunde",
	"propertyType:leisure": "Sommerhus | Sommerhuse",
	"propertyType:leisurePlot": "Fritidsgrund | Fritidsgrunde",
	"propertyType:office": "",
	"openhouseReferences": "Hvor er der fremvisning",
	"isInterestedIn": "Er interesseret i",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "Hele projektet",
	"getInTouch": "Kontakt os",
	"leadFormTitle": "Angiv interesse",
	"leadConfirm": "Tak for din interesse",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Kommer til salg",
		"sale": "Til salg",
		"sold": "Solgt",
		"reserved": "Reserveret"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Kommer til salg",
		"sale": "Til salg",
		"sold": "Solgt",
		"reserved": "Reserveret"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Kommer til salg",
		"reserved": "Reserveret",
		"rent": "",
		"rented": ""
	},
	"viewResidentials": "Se boliger",
	"status": "Status",
	"bidAction": "Køb",
	"residentialSaleFee": "Omkostninger",
	"residentialSaleFixed": "Fastpris",
	"residentialSaleFeeIncluded": "Den angivne pris er inkl. omkostninger",
	"contact": "Kontakt | Kontakter",
	"editContact": "Ændr kontakt",
	"createContact": "Opret kontakt",
	"contactUpsell": "Jeg ønsker oplysninger om lignende projekter",
	"leadPrivacy": "Jeg ønsker at blive kontaktet i overensstemmelse med {0}",
	"contractPrivacy": "Jeg er indforstået med {0}",
	"salesmanVisibility": "Kontaktoplysningerne til kundeservicemedarbejderne på dette projekt vises på projektets webside",
	"areYouSure": "Er du sikker?",
	"somethingWentWrong": "Noget gik galt",
	"completed": "Gennemført",
	"complete": "Gennemfør",
	"completing": "Gennemfører",
	"youAreAboutToRemove": "Du er i gang med at slette",
	"removeRelationsTitle": "Du sletter også tilhørende:",
	"showAll": "Vis alle",
	"attachment": "Bilag | Bilag",
	"preview": "Forhåndsvisning",
	"transferToUser": "Flyt tilknyttede data til:",
	"upcomingDeadlines": "Kommende frister",
	"noDeadlines": "Ingen kommende frister",
	"recentActivity": "Seneste hændelser",
	"noActivity": "Ingen hændelser",
	"task": "Opgave | Opgaver",
	"noTasks": "Ingen opgaver",
	"new": "Ny",
	"showCoordinates": "Vis koordinater",
	"readOurTerms": "Gennemlæs aftalevilkårene",
	"iAcceptTerms": "Jeg har læst og accepterer aftalevilkårene",
	"sendAndAccept": "Gå videre",
	"privacy": "Erklæring om privatlivsbeskyttelse",
	"notfoundTitle": "Siden eksisterer ikke",
	"projectWillPublish": "Offentliggøres snart",
	"projectWillPublishAt": "Offentliggøres den {date}",
	"projectUnpublished": "Projektet er afsluttet",
	"pageMadeBy": "Siden er lavet i",
	"processedBy": "Behandlet af",
	"markAsProcessed": "Marker som behandlet",
	"openhouseAtValue": "Fremvisning på {value}",
	"noMoreResidentials": "Ikke flere boliger",
	"welcomeName": "Velkommen, {name}",
	"youHaveBeenAddedToCompany": "Du er tilføjet som bruger i {company}.",
	"activateAccount": "Aktiver konto",
	"pressButtonToActivateAccount": "Tryk på knappen for at vælge dit password og aktivere kontoen.",
	"orgNo": "Org.nr.",
	"howToGetLatLng": "For at få placeret projektet på kortet anbefaler vi dig at bruge {0} for at udtrække de rigtige koordinater. Adresseopslag indeholder ofte ikke nye adresser, og du er derfor nødt til at udtrække dette manuelt. Det betyder også, at placeringen bliver mere præcis.",
	"image": "Billede | Billeder",
	"flatfinderBuilder": "Layout",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Afbryd tegning",
				"text": "Afbryd"
			},
			"finish": {
				"title": "Gennemfør tegning",
				"text": "Gennemfør"
			},
			"undo": {
				"title": "Slet det sidst tegnede punkt",
				"text": "Slet det sidste punkt"
			},
			"buttons": {
				"polygon": "Tegn en markering"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Fejl!",
				"tooltip": {
					"start": "Tryk for at tegne en markering.",
					"cont": "Tryk for at fortsætte med at tegne en markering.",
					"end": "Tryk på det første punkt for at lukke denne markering."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Gem ændringer.",
					"text": "Gem"
				},
				"cancel": {
					"title": "Afbryd redigering, afvis alle ændringer.",
					"text": "Afbryd"
				},
				"clearAll": {
					"title": "Slet alle markeringer.",
					"text": "Slet alle"
				}
			},
			"buttons": {
				"edit": "Rediger markeringer.",
				"editDisabled": "Ingen markeringer at redigere.",
				"remove": "Slet markeringer.",
				"removeDisabled": "Ingen markeringer at slette."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Træk punkterne, eller marker for at redigere.",
					"subtext": "Tryk på afbryd for at afvise ændringer."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Tryk på en markering for at slette."
				}
			}
		}
	},
	"theme": "Tema",
	"selectTheme": "Vælg tema",
	"show": "Vis",
	"activateMarketingButton": "Køb ekstra synlighed",
	"couldNotLoadContent": "Kunne ikke hente indhold",
	"couldNotLoadContentMessage": "Prøv at hente siden ind igen. Hvis problemet fortsætter, skal du kontakte {0}.",
	"personalInformation": "Personlige oplysninger",
	"bidInformation": "Budoplysninger",
	"bidAuthentication": "Legimitation",
	"bidSigning": "Signering",
	"bidConfirmation": "Bud signeret",
	"purchaseSigning": "Signering",
	"purchaseConfirmation": "Købskontrakt signeret",
	"socialSecurityNumber": "Personnummer",
	"bidApplicant": "Køber",
	"bidHasCoApplicant": "Tilføj medkøber",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Medkøber",
	"amount": "Beløb",
	"bidExpiresAt": "Acceptfrist",
	"bidTakeoverAt": "Ønsket overtagelse",
	"bidCondition": "Eventuelle forbehold",
	"passwordEnforcementMessage": "Passwordet skal bestå af mindst 8 tegn, store/små bogstaver og mindst 1 tal",
	"bank": "Bank",
	"financeContact": "Kontaktperson",
	"financingPlan": "Finansieringsplan",
	"signingAuthority": "Autoriseret underskriver",
	"bidContract": "Købsbekræftelse",
	"purchaseContract": "Købskontrakt",
	"shopContract": "Tilvalgskontrakt",
	"shop-partialContract": "Tilvalgskontrakt – Delsignering",
	"bidSignedMessage": "Dit bud er signeret og sendt. Du vil om kort tid modtage en bekræftelse via e-mail. Så snart dit bud er blevet behandlet, vil du få svar via e-mail.",
	"purchaseSignedMessage": "Din købskontrakt er signeret og sendt. Du vil om kort tid modtage en bekræftelse via e-mail. Så snart din købskontrakt er blevet behandlet, vil du få svar via e-mail.",
	"gotoProject": "Gå til projektet",
	"parent": "Forælder",
	"goBack": "Gå tilbage",
	"removeDemoContent": "Slet demo-indhold",
	"unsavedChangesDialog": "Du har ændringer, der ikke er gemt, og som går tabt, hvis du fortsætter. Er du sikker på, at du vil fortsætte?",
	"confirmation": "Bekræftelse",
	"yourDocumentsAreAttached": "Vedlagt finder du alle dokumenter",
	"accept": "Godkend",
	"decline": "Afslå",
	"document": "Dokument | Dokumenter",
	"noDocuments": "Ingen dokumenter",
	"projectsite": "Projektwebside",
	"signer": "Underskriver | Underskrivere",
	"youDoNotHaveSignAuthority": "Kun den autoriserede underskriver kan godkende/afvise dette dokument",
	"update": "Opdater",
	"fieldsToSync": "Data, der skal overskrives",
	"selectAll": "Vælg alle",
	"residentialTemplateSyncReferences": "Opdater tilknyttede boliger",
	"askResidentialTemplateSyncReferences": "Ønsker du at opdatere tilknyttede boliger?",
	"projectDomainSetup": "For at ændringen bliver synlig på nettet, skal du oprette en DNS pointer for dit domæne (se herunder). Husk, at det kan tage lidt tid, inden ændringen bliver synlig på nettet. Kontakt os via chatten, hvis du har spørgsmål.",
	"lookingForAUserAdd": "Kan du ikke finde den bruger, du leder efter?",
	"select": "Vælg",
	"syncReferencesWarning": "Vær forsigtig, når du vælger, hvilke data der skal overskrives. Alle valgte felter vil blive overskrevet med det nye indhold.",
	"themeDoesNotSupportColor": "Det valgte tema understøtter ikke muligheden for temafarve.",
	"themeColor": "Temafarve",
	"youAreHere": "Du er her",
	"resetSelection": "Nulstil valg",
	"selection": "Valg",
	"thisIsADemo": "Dette er en demo",
	"userIsGuest": "Gæst/Har ikke adgang til systemet",
	"guest": "Gæst",
	"inviteSent": "Invitation sendt",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "Denne besked er sendt til",
	"tourSeoAnalyticsTagManager": "SEO/Analytics/Tag Manager",
	"tourDeveloperTools": "Brug af udviklerværktøj",
	"tourBusinessSite": "Sådan ændrer du virksomhedsside",
	"tourRecommendedImageFormat": "Anbefalede billedformater",
	"tourHowToAddUser": "Sådan tilføjer du en bruger",
	"tourHowToCreateProjects": "Sådan opretter du projekter",
	"tourSingleResidentialSale": "Enkelt boligsalg",
	"tourHowToAddLeads": "Sådan tilføjer du interessenter",
	"tourHowToCreateResidentials": "Sådan opretter du boliger",
	"addonPresentation": "Præsentation",
	"addonBid": "Budgivning",
	"addonPurchase": "Købskontrakt",
	"addonShop": "@:shop",
	"addonShopPurchase": "Tilvalgsbutik – Signering",
	"tourAboutProjectDashboard": "Om projekt-dashboardet",
	"tourHowToEditResidentials": "Sådan redigerer du boliger",
	"tourHowToDeleteResidentials": "Sådan sletter du boliger",
	"tourTitleOnScreen": "Hjælpeguider (Interaktive)",
	"tourTitleCourseVideos": "Oplæringsvideoer",
	"tourTitleManuals": "Hjælpeguider (Manual)",
	"flatfinderIsEntry": "Primær boligvælger (vis denne først på projektsiden)",
	"flatfinderCreateResidentialWarning": "Når du opsætter en boligvælger, skal du knytte den til boliger. Det er derfor vigtigt, at du opretter boliger, inden du opretter boligvælgeren.",
	"flatfinderCreateResidentialWarningTitle": "Vi ser, at du ikke har oprettet nogen boliger",
	"flatfinderCreateResidentialWarningException": "Hvis du ikke skal opsætte boliger nu, kan du se bort fra denne meddelelse og fortsætte.",
	"continue": "Fortsæt",
	"default": "Standard",
	"richTextUpdateLink": "Opdater link",
	"richTextAddLink": "Tilføj link",
	"multiplicatorMonthly": "mnd",
	"multiplicatorProject": "projekt",
	"contractStatusAccepted": "Godkendt",
	"contractStatusDeclined": "Afslået",
	"contractStatusDraft": "Udkast",
	"contractStatusSigned": "Et dokument afventer godkendelse",
	"contractStatusUnsigned": "Du har et dokument til signering",
	"bidErrorStatus": "Boligen er ikke til salg",
	"bidErrorAddon": "Modulet for budafgivelse er ikke aktiveret for dette projekt",
	"purchaseErrorStatus": "Boligen er ikke solgt",
	"purchaseErrorAddon": "Modulet for købskontrakt er ikke aktiveret for dette projekt",
	"signed": "Signeret",
	"gotoSigning": "Gå til signering",
	"sign": "Signer",
	"username": "Brugernavn",
	"accountUrl": "Link til konto",
	"password": "Password",
	"otherBidAcceptedMessage": "Et andet bud blev accepteret",
	"bidConfirmationMessage": "Vi har modtaget dit bud, og du får svar fra os så hurtigt som muligt",
	"bidAcceptMessage": "Dit bud er accepteret",
	"bidDeclinedMessage": "Dit bud er afslået",
	"purchaseConfirmationMessage": "Vi har modtaget din købskontrakt, og du får svar fra os så hurtigt som muligt",
	"purchaseAcceptMessage": "Din købskontrakt er accepteret",
	"purchaseDeclinedMessage": "Din købskontrakt er afvist",
	"shopConfirmationMessage": "Vi har modtaget din signerede tilvalgsbestilling. Når modparten har signeret denne, vil du modtage en bekræftelse. Vedlagt finder du en oversigt over din bestilling.",
	"shopAcceptMessage": "Din tilvalgskontrakt er accepteret",
	"shopDeclinedMessage": "Din tilvalgskontrakt er afvist",
	"contractExpiredMessage": "Fristen er udløbet",
	"reason": "Begrundelse",
	"pressHereToCancelBid": "Vil du annullere købet?",
	"youAreUsingBidAddon": "Du bruger Kvass – Købsmodul",
	"pressHere": "Klik her",
	"void": "Annuller",
	"reasonWillBeSentToApplicants": "Denne besked vil blive videresendt til kunden",
	"howToBuildContract": "For at dokumentet skal kunne udfyldes korrekt skal du opsætte en PDF, der kan udfyldes. Klik på Hjælp-knappen nedenfor for at få vist, hvordan du gør det.",
	"contractType": "Type",
	"createDocument": "Opret dokument",
	"upload": "Upload",
	"bidUploadInfo": "Efter at du har uploadet den signerede købsbekræftelse, vil boligen blive markeret som solgt",
	"onSoldStatusDialog": "For at markere denne bolig som solgt, skal du uploade den signerede købsbekræftelse.",
	"excludingVat": "ekskl. moms",
	"includingVat": "inkl. moms",
	"priceOnRequest": "Pris ved forespørgsel",
	"priceFrom": "Pris fra",
	"pricePerMonth": "Pris pr. måned",
	"salePrice": "Udsalgspris",
	"priceMargin": "Prisforhøjelse",
	"purchasePrice": "Indkøbspris",
	"readMore": "Læs mere",
	"upsaleRequestTemplate": "Jeg ønsker hjælp med \"{0}\"",
	"publishedFrom": "Offentliggjort fra",
	"publishedTo": "Offentliggjort til",
	"customerService": "Kundeservice",
	"residentialTemplateExplanation": "Ved at oprette boligtyper slipper du for at vedligeholde det samme grundlag flere gange. Du kan nemt og hurtigt opdatere alle boliger/lejligheder, der er baseret på denne boligtype, mens du samtidig bevarer muligheden for at ændre på boligerne/lejlighederne individuelt på projektniveau.",
	"unitTemplateExplanation": "",
	"product": "Produkt | Produkter",
	"createProduct": "Opret produkt",
	"allProducts": "Alle produkter",
	"category": "Kategori | Kategorier",
	"productCategorySample": "f.eks. Gulv, Parket, VVS",
	"more": "Mere",
	"residentialStatusBidLock": "Du har aktiveret \"Købsmodul\" og kan ikke ændre status til eller fra \"solgt\" manuelt. Gå ind på boligen for at ændre status",
	"noAnalyticsCollected": "Vi har ikke indsamlet nok data endnu. Du vil få vist statistikken her, så snart folk besøger websiden.",
	"sendEmail": "Send e-mail",
	"customThemeActiveMessage": "Du anvender en skræddersyet skabelon på dette projekt.",
	"validDnsSetupMessage": "Domænet er ikke opsat korrekt – tjek instruktionerne nedenfor, og prøv igen.",
	"siteSettings": "Sideindstillinger",
	"showOpenhouseOnRequestIfNone": "Vis \"@:openhouseOnRequest\", hvis der ikke er planlagt nogen fremvisninger",
	"showLeadUpsell": "Vis \"@:contactUpsell\" på interessentskemaet",
	"showShopOnResidentialPage": "Vis tilvalgsbutik på boligsiderne",
	"showShop": "Vis tilvalgsbutik",
	"typeHere": "Skriv her",
	"shopCategoryExamples": "f.eks. Køkken, Bad",
	"shopSubcategoryExamples": "f.eks. Fliser, Indretning",
	"shopAddProduct": "Vælg produkt",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Tilføj underkategori",
	"lookingForAProductAdd": "Kan du ikke finde det produkt, du leder efter?",
	"hideSold": "Skjul solgte",
	"assetManagerTitleBrochureDirect": "Download salgsmateriale",
	"assetManagerTitleBrochureLead": "Download salgsmateriale",
	"assetManagerTitleBrochureRequest": "Få salgsmaterialet tilsendt",
	"assetManagerActionBrochureLead": "Download salgsmateriale",
	"assetManagerActionBrochureRequest": "Send",
	"assetManagerFeedbackBrochureRequest": "Du vil få tilsendt salgsmaterialet inden for kort tid.",
	"assetManagerCommentBrochureRequest": "Jeg ønsker at få tilsendt salgsmaterialet.",
	"brochureDownloadStrategyLabel": "Vælg, hvordan salgsmaterialet kan downloades",
	"brochureDownloadStrategyDirect": "Download direkte",
	"brochureDownloadStrategyLead": "Angiv interesse først",
	"brochureDownloadStrategyRequest": "Send forespørgsel/Udsend manuelt",
	"selected": "Valgt",
	"shopDefaultProduct": "Standardlevering",
	"shopProductUpgrade": "Opgraderingsmuligheder",
	"totalPrice": "Totalpris",
	"shopContactSellerForOtherInquiries": "Hvis du har andre ønsker, bedes du kontakte sælger",
	"shopNotAvailableSize": "Åbn denne side på en større skærm for at få den bedste oplevelse af tilvalgsbutikken",
	"gotoItem": "Gå til {0}",
	"activate": "Aktiver",
	"whatDoYouWantToCopy": "Hvad skal kopieres?",
	"helpTools": "Manualer/Vejledninger",
	"needHelp": "Trenger du hjælp?",
	"title": "Tittel",
	"nearbyarea": "Området",
	"shopSync": "Kopier",
	"shopSyncAll": "Hele tilvalgsbutikken",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Opgave",
	"badge-customcontract": "",
	"prepareContract": "Klargøring af kontrakt",
	"prepareShop": "Klargøring af tilvalg",
	"preparePurchaseContract": "Klargøring af købskontrakt",
	"buyer": "Køber | Købere",
	"sendToSigning": "Send til signering",
	"deadline": "Frist",
	"shopLink": "Link til tilvalgsbutik (præsentation)",
	"shopLinkWithSelection": "Link til tilvalgsbutik (valg & signering)",
	"shopSigning": "Signering",
	"shopConfirmation": "Bekræftelse",
	"shopIntro": "Indledning",
	"shopSummary": "Opsummering",
	"shopSignedMessage": "Din tilvalgskontrakt er signeret og sendt. Du vil om kort tid modtage en bekræftelse via e-mail. Så snart din tilvalgskontrakt er blevet behandlet, vil du få svar via e-mail.",
	"shopSigningNotReady": "Der er ikke åbnet for signering endnu, og du vil få besked, så snart du kan signere",
	"startShopContract": "Start tilvalgsproces",
	"shopReadyTitle": "Din tilvalgsbutik er klar",
	"shopReadyMessage": "Du kan nu se og vælge, hvilke tilvalg du ønsker på din bolig. \nDer er ikke åbnet for signering endnu, men du kan allerede nu foretage dine første valg. Så snart der åbnes for signering, får du besked.",
	"shopWelcomeMessage": "Velkommen til din tilvalgsbutik – her kan du se og vælge, hvilke tilvalg du ønsker på din bolig.\n\nSå snart du har foretaget dine valg, kan du gå videre til opsummeringen, hvor du vil få mulighed for at signere din bestilling.",
	"shopUnsignedMessage": "Du kan nu vælge at signere dine tilvalg",
	"gotoShop": "Gå til tilvalgsbutikken",
	"shopContractStatusReady": "Klar (sendt til køber)",
	"shopContractStatusDraft": "Kladde",
	"shopContractStatusUnsigned": "Afventer signatur",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "Fristen er udløbet",
	"shopContractNotSold": "Denne bolig er ikke solgt endnu. Så snart boligens status ændres til solgt, vil du kunne påbegynde signeringsprocessen.",
	"shopContractAttachment": "Bilag (signeres af køber)",
	"shopContractStartRequirements": "Inden du kan begynde tilvalgsprocessen, skal du tilføje køber. \nHusk at gemme ændringerne. ",
	"shopContractRememberSignableFrom": "Husk at indstille \"@:signableFrom\", sådan at køberen kan signere tilvalgsbestillingen.",
	"file": "Fil | Filer",
	"import": "Importer",
	"productImport": "Produktimport",
	"importedXItem": "Importerede {count} {item}",
	"importFailedResolveIssues": "Importen kunne ikke gennemføres. Nedenfor finder du en oversigt over, hvad der er gået galt. Ret venligst dette, og prøv igen.",
	"pressButtonBelowToGetStarted": "Tryk på knappen herunder for at komme i gang",
	"signingDeadlineIs": "Fristen for signering er",
	"shopLinkPresentation": "Præsentation",
	"shopLinkSigning": "Valg/Signering (for køber)",
	"export": "Eksporter",
	"page": "Side | Sider",
	"tourGetStartedSingleShop": "Sådan kommer du i gang med: Enkelt modul – Tilvalg",
	"tourHowToAddPartDeadlinesShop": "Sådan sætter du delfrister på tilvalgssignering",
	"tourHowIfOrIfNotShopWork": "Sådan fungerer hvis/hvis ikke-processen for tilvalg",
	"tourGetStartedShop": "Sådan kommer du i gang med: Tilvalgsbutik",
	"tourGetStartedShopContract": "Sådan kommer du i gang med: Digital tilvalgssignering",
	"tourTaskManagement": "Sådan fungerer opgavestyring i kundeopfølgningssystemet",
	"tourGetStartedPurchaseContract": "Sådan kommer du i gang med: Digital købskontrakt",
	"tourGetStartedBidContract": "Sådan kommer du i gang med: Digital købsmodul",
	"tourGetStartedSingleBidContract": "Sådan kommer du i gang med: Enkelt modul – Digital købsmodul",
	"tourHowToEditProjectResidentials": "Sådan redigerer du projektet/boliger",
	"tourHowToCreateFlatfinder": "Sådan opretter/redigerer du boligvælgere",
	"tourHowToCreateResidentialTemplate": "Sådan opretter/redigerer du boligtyper",
	"tourHowToBuildContract": "Sådan opsætter du købsbekræftelse",
	"tourHowDashboardWorks": "Sådan fungerer dashboardet",
	"tourHowToCreateProjectTeaser": "Sådan opretter du en teaser (Kommer til salg)",
	"tourRoles": "Sådan administrerer du brugere",
	"tourHowToPublish": "Sådan offentliggør du projektet under et domæne",
	"tourTitleAdditionalServices": "Har du brug for hjælp?",
	"tourHowToBuildPDF": "Sådan opsætter du en PDF, der kan udfyldes",
	"tourHowToHandleLead": "Sådan håndterer du interessenter",
	"tourHowToHandleOpenhouse": "Sådan håndterer du fremvisninger",
	"howManyUnitsOnProject": "Hvor mange enheder skal sælges i alt?",
	"attachmentIsEncryptedWithSSN": "Bemærk, at bilaget er krypteret – passwordet er dit personnummer. Det dokument, du har modtaget, er en kopi, og originalen ligger hos sælger.",
	"contractApprovalWarning": "Husk at kontrollere dokumentets indhold, inden du godkender det.",
	"addon:project": "Projekt",
	"addon:project:presentation": "Projekt-/Boligside",
	"addon:project:bid": "Købsmodul",
	"addon:project:purchase": "Købskontrakt",
	"addon:project:shop": "Tilvalg – Præsentation",
	"addon:project:shop-purchase": "Tilvalg – Salg",
	"addon:custom-contract": "",
	"addon:api": "",
	"addon:flatfinder": "",
	"addon": "Modul | Moduler",
	"fixedPrice": "Fastpris",
	"areaPrice": "Pris pr. m²",
	"customerPrice": "Pris til kunde",
	"establishment": "Etablering",
	"ownershipType:leasehold": "Obligation",
	"ownershipType:freehold": "Ejer (husejer)",
	"ownershipType:other": "Andet",
	"ownershipType:partOwnership": "Andel",
	"ownershipType:collectiveOwnership": "Aktie",
	"plotOwnershipType:leasehold": "Obligation",
	"plotOwnershipType:freehold": "Ejer (husejer)",
	"plotOwnershipType:other": "Andet",
	"plotOwnershipType:partOwnership": "Andel",
	"plotOwnershipType:collectiveOwnership": "Aktie",
	"subpage": "Underside | Undersider",
	"orderItem": "Bestil {item}",
	"ShopPurchaseRequiresShop": "For at kunne anvende \"Tilvalg – Salg\" skal du også bruge \"Tilvalg – Præsentation\"",
	"projectUnitLimitReached": "Du kan oprette endnu {0} enheder på dette projekt. For at tilføje flere enheder skal du først opgradere til en større licens. Kontakt venligst supporten på support@kvass.no eller på chat for at opgradere.",
	"slug": "Slug",
	"content": "Indhold",
	"setArea": "Angiv areal",
	"rounding:none": "Ingen",
	"priceRounding": "Prisafrunding",
	"exampleShort": "f.eks.",
	"productOverrideMessage": "{value} er forbundet til et produktkatalog. Tryk på \"Rediger\" for anføre en brugerdefineret værdi.",
	"productOverrideReset": "Forbind \"{value}\" med produktkatalog – fjern brugerdefineret værdi.",
	"priceMissingArea": "Areal mangler",
	"total": "Total",
	"instagramProfile": "Instagram-profil",
	"numberOfPosts": "Antal indlæg",
	"followUsOn": "Følg os på {value}",
	"customFieldsResidentialTemplatesLabel": "Præsentation af boligtyper",
	"signableFrom": "Kan signeres fra",
	"setPartialDeadlines": "Anfør delfrister",
	"removePartialDeadlines": "Fjern delfrister",
	"partialDeadline": "Delfrist | Delfrister",
	"madeBy": "En tjeneste fra",
	"gotoSummary": "Gå til opsummering",
	"selectCategoriesToSign": "Vælg kategorier, som skal signeres",
	"shopBuyerStatusDraft": "Ikke åben for signering endnu",
	"shopBuyerStatusUnsigned": "Klar til signering",
	"wholeShop": "Hele tilvalgsbutikken",
	"company": "Virksomhed",
	"companyName": "Virksomhedsnavn",
	"businessSite": "Virksomhedsside",
	"private": "Privat",
	"footer": "Bundtekst",
	"creatingContracts": "Opretter dokumenter til signering, vent et øjeblik",
	"pressButtonBelowToGoToShop": "Tryk på knappen herunder for at gå til din tilvalgsbutik",
	"youHaveNewShopInfo": "Du har modtaget opdaterede oplysninger i din tilvalgsbutik",
	"youHaveAnUpcomingDeadline": "Du har en frist, der nærmer sig",
	"hiName": "Hej, {name}",
	"shopPresentationSidebarDescriptionTitle": "Opsæt din bolig",
	"shopPresentationSidebarDescriptionContent": "Her kan du se, hvilke opgraderinger der er tilgængelige på denne bolig.",
	"signingLoadingMessage": "Vi færdiggør dine dokumenter – vent et øjeblik (dette tager ca. 15-30 sek.)",
	"tag": "Tag | Tags",
	"noTags": "Ingen tags",
	"youHaveANewMessageFrom": "Du har modtaget en ny besked fra {from}",
	"nameSays": "{name} siger",
	"gotoDialog": "Gå til dialogen",
	"youCanAswerThisEmail": "Du kan svare på denne e-mail – dit svar bliver videresendt",
	"send": "Send",
	"note": "",
	"thisMessageWasSentWith": "Denne besked er sendt med",
	"addCategory": "Tilføj kategori",
	"availableIf": "Tilgængelig, hvis",
	"availableIfNot": "Ikke tilgængelig, hvis",
	"xActiveConditions": "Ingen aktive regler | {count} aktiv regel | {count} aktive regler",
	"setConditions": "Anfør regler",
	"displayName": "Visningsnavn",
	"useDisplayName": "Brug et andet visningsnavn",
	"statisticsVisitors": "Besøgstal",
	"statisticsSalesResidentials": "Salgstal",
	"statisticsSalesShop": "Salgstal (Tilvalg)",
	"externalUserAlert": "Du prøver at tilføje en bruger, som er udenfor din organisation ({domain}). \nVedkommende vil få tilgang til projekterne og tilhørende data i løsningen.\n\nBekræft venligst, at du ønsker at tilføje {email}.",
	"sendMessageToSeller": "Send besked til sælger",
	"sendMessageToSellerButtonLabel": "Er der noget, du er i tvivl om? – Kontakt os her",
	"sent": "Sendt",
	"member": "Medlem | Medlemmer",
	"editTask": "Rediger opgave",
	"createTask": "Opret opgave",
	"undone": "Ikke startet",
	"noDescription": "Ingen beskrivelse",
	"noDeadline": "Ingen frist",
	"youHaveANewTask": "Du har fået en ny opgave",
	"gotoTask": "Gå til opgaven",
	"youHaveX": "",
	"gotoDashboard": "Gå til dashboardet",
	"youHaveXTaskDueIn": "Du har {count} opgave med frist {dueby} | Du har {count} opgaver med frist {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Tilføj bundtekst, f.eks: Om os/Adgang",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Vælg, hvilke af dine boligtyper du ønsker at præsentere på projektsiden.",
	"projectAdditionalFieldsDescriptionArea": "Tilføj tekster og billeder for at præsentere området eller lignende.",
	"projectAdditionalFieldsDescriptionSubpage": "Opret undersider, hvor du kan præsentere billeder og tekst. Du kan linke til undersider ved at tilføje links i tekstfelterne.",
	"clone": "Kopier",
	"type": "Type | Typer",
	"filter": "Filtrer",
	"noShopDefaultProduct": "Ingen standardlevering",
	"customer": "Kunde | Kunder",
	"purchaseManuallyConfirmed": "Den uploadede købskontrakt er signeret",
	"cannotAddMoreUsers": "Du kan ikke tilføje flere brugere. Kontakt venligst supporten, hvis du ønsker at tilføje flere.",
	"videoPreviewMessage": "Tilføj {type} link til forhåndsvisning af video",
	"videoLink": "{type} link",
	"profile": "Profil | Profiler",
	"redirectToExternalSite": "Videresend til ekstern side",
	"residentialRedirectUrlExample": "f.eks. https://...",
	"optional": "valgfrit",
	"residentialRedirectUrlExplanation": "Brug dette felt, hvis du ønsker at videresende besøgende til et andet websted",
	"createdAt": "Oprettet den",
	"overview": "Oversigt",
	"mediaTypeComboMessage": "Eller tryk på knappen for at vælge blandt andre medietyper",
	"mediaTypeDefaultMessage": "Vælg medietype via knappen",
	"userIsDeveloper": "Vis udviklerværktøj",
	"noFloorplansSetup": "Ingen plantegninger opsat",
	"mark": "Marker",
	"createResidentialTemplateBeforeShop": "For at kunne oprette tilvalgsbutikken skal du først oprette boligtypen",
	"noShopsetsSetup": "Ingen produktpakker opsat",
	"shopSet": "Produktpakke | Produktpakker",
	"productsThatArePartOfSet": "Produkter i produktpakken",
	"manualSelectAndSkip": "Vælg selv (Spring over)",
	"event": "Hændelse | Hændelser",
	"url": "Url",
	"enabled": "Aktiveret",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Opret webhook",
	"setting": "Indstilling | Indstillinger",
	"webhookSecret": "Secret",
	"attempt": "Forsøg",
	"of": "af",
	"log": "Log | Log",
	"noWebhooks": "Ingen webhooks",
	"webhookIsSystem": "Systemintegration",
	"noLogs": "Ingen logs",
	"token": "Token | Tokens",
	"createToken": "Opret token",
	"noTokens": "Ingen tokens",
	"acl": "Adgangskontrol",
	"tokenSecretMessage": "Kopier venligst denne nøgle, og gem den på et sikkert sted. Af sikkerhedsårsager viser vi den kun én gang.",
	"shopContractNoticeSellerApproachingSubject": "Købers signeringsfrist udløber",
	"shopContractNoticeSellerOverdueSubject": "Købers signeringsfrist er udløbet",
	"shopContractNoticeSellerExpires": "Køber har ikke gennemført signering af tilvalgskontrakt.",
	"unit": "Enhed | Enheder",
	"noUnits": "",
	"noMoreUnits": "",
	"createUnit": "",
	"unitTemplate": "",
	"residentialSaleWithBroker": "",
	"residentialSaleSelf": "",
	"residentialSaleWithBrokerBidHelperText": "Her kan du tilføje et valgfrit link, som du kan bruge til at sælge enheden. Linket vil være tilgængeligt \nunder knappen \"køb\" på boligsiden. ",
	"bidContractMissingShowBidUrl": "Købsbekræftelse mangler Upload købsbekræftelse på projektet for at få vist link til budafgivelse. ",
	"residentialSaleBidHelperText": "Med dette link kan du sælge enheden digitalt. \nLinket er tilgængeligt under knappen “køb” på boligsiden, men linket kan også kopieres herfra. \nKøb, der bliver gennemført via dette link, vil blive vist på sælgerens dashboard. ",
	"residentialSaleBidActiveHelperText": "Købsmodulet er aktiveret. Hvis du ønsker at tage en anden salgsmetode i brug, kan du bestille integration med systemet. \nKontakt os via knappen herunder for at få flere oplysninger og for at bestille. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Tryk på knappen for at få flere oplysninger om tilvalgsbutikken",
	"copy": "Kopier",
	"copied": "Kopieret",
	"paywallContentFieldUsedByFollowingModules ": "Disse felter er ikke relevante for aktiverede moduler",
	"paywallContentPressButtonToReadMore": "Tryk på knappen for at læse mere eller aktivere modulet | Tryk på en af knapperne for at læse mere eller aktivere modulet",
	"deliveryLogClickLabel": "{resource} leveret – åbnet",
	"deliveryLogOpenLabel": "{resource} leveret – sæt",
	"deliveryLogFailedLabel": "{resource} kunne ikke leveres",
	"deliveryLogDeliveredLabel": "{resource} leveret – ikke åbnet",
	"deliveryLogUnknownLabel": "{resource} – afventer levering",
	"invite": "Invitation",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "En eller flere frister er udløbet",
	"projectShopsOpenByBuyer": "Åbnet af køber",
	"contractStatusNotAccepted": "Afventer godkendelse",
	"noMoreShops": "Ikke flere tilvalgsbutikker",
	"projectShopsCreateResidentialsWarning": "Opret boliger, inden du opretter tilvalgsbutikker.",
	"projectShopsEditShop": "Ikke oprettet",
	"projectShopsCreateResidentials": "Opret boliger",
	"projectShopsNotSold": "Ikke solgt",
	"projectSaleBrokerGuide1": "",
	"projectSaleBrokerGuide2": "",
	"projectSaleBrokerGuide3": "",
	"residentialsFlatfinderGuideInfo": "",
	"shop": "Tilvalgsbutik | Tilvalgsbutikker",
	"devtools": "",
	"embed": "",
	"imageRecommendation": "",
	"height": "",
	"width": "",
	"imageFlatfinderRecommendation": "",
	"consentRequiredTitle": "",
	"consentRequiredDescription": "",
	"consentStatisticsTitle": "",
	"consentStatisticsDescription": "",
	"consentMarketingTitle": "",
	"consentMarketingDescription": "",
	"consentDialogTitle": "",
	"consentDialogDescription": "",
	"consentDialogLabelAccept": "",
	"consentDialogLabelAcceptAll": "",
	"consentDialogLabelConfirm": "",
	"consentDialogLabelConfigure": "",
	"editConsents": "",
	"noAttachments": "",
	"tourVideoUpload": "",
	"language": "Sprog",
	"textOverwriteWarning": "",
	"cookieVideoBlockMessage": "",
	"sizes": "",
	"productLink": "",
	"showDefaultSelection": "",
	"noUpgradesSelected": "",
	"setValue": "",
	"internalName": "",
	"useInternalName": "",
	"shopWelcomeText": "",
	"scopedProduct": "",
	"contactImport": "",
	"importTemplateDescription": "",
	"socialMedia": "",
	"downloadTemplate": "",
	"projectCreationVideoMessage": "",
	"singleResidential": "",
	"myPage": "",
	"contractStatusNotAcceptedBySeller": "",
	"contractStatusAcceptedBySeller": "",
	"followSoMeTitle": "",
	"projectAdditionalFieldsSoMeDescriptionArea": "",
	"projectAdditionalFieldsSeoDescriptionArea": "",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "",
	"SEO": "",
	"facebookAds": "",
	"back": "Tilbage",
	"newFolder": "",
	"dragFolderOrDrop": "",
	"managementOperationMaintenance": "",
	"residentialAccount": "",
	"clickToFilter": "",
	"imagesOnlyAsIllustration": "",
	"foundXResults": "",
	"active": "",
	"inactive": "",
	"contract": "",
	"buyConfirmation": "",
	"residentialAccountLogin": "",
	"loginPressButton": "",
	"loginVisitLink": "",
	"loginLinkExpires": "",
	"loginGetNewLink": "",
	"residentialAccountYourUnits": "",
	"residentialAccountLoginTooSeeUnits": "",
	"loginEnterValue": "",
	"loginSuccessMessage": "",
	"sortOn": "",
	"highLow": "",
	"lowHigh": "",
	"syncUser": "",
	"lowHighLetter": "",
	"highLowLetter": "",
	"addFilter": "",
	"resetFilter": "",
	"validTo": "",
	"registrationComplete": "",
	"clickToLogin": "",
	"welcomeToKvass": "",
	"enterPasswordAndClickComplete": "",
	"tokenExpiredRequestNew": "",
	"author": "",
	"post": "",
	"createPost": "",
	"shopShort": "",
	"developer": "",
	"access": "",
	"supplier": "",
	"technicalDescription": "",
	"addTechnicalDescription": "",
	"switchTenant": "",
	"leadMessageSent": "",
	"externalUserAlertAdmin": "",
	"externalUserAlertNoRights": "",
	"externalUserAlertRoles": "",
	"externalUserAlertAccess": "",
	"shopPresentationPreviewAlert": "",
	"addon:project:complaint": "",
	"archived": "",
	"showArchived": "",
	"archive": "",
	"integrationCompliance": "",
	"documentSigned": "",
	"documentSignedConfirmationMessage": "",
	"integrationNotificationEmailBody": "",
	"integrationNotificationEmailBodyReminder": "",
	"integrationNotificationEmailSubject": "",
	"noContracts": "",
	"awaitingSignature": "",
	"residentialAddressControl": "",
	"residentialAccountNotSold": "",
	"createContract": "",
	"lastUpdated": "",
	"complaint": "",
	"lookingForAContactAdd": "",
	"orderConfirmationCondition": "",
	"orderConfirmationConditionShort": "",
	"orderConfirmation": "",
	"unitLimitMessage": "",
	"taskStatusUndone": "",
	"taskStatusDone": "",
	"taskStatusRejected": "",
	"taskStatusRequest": "",
	"subjectArea": "",
	"showCompleted": "",
	"complaintRequest": "",
	"complaintTermsOfRequest": "",
	"complaintAddSelection": "",
	"complaintRegister": "",
	"companyOrder": "",
	"activatedBy": "",
	"orderConfirmationAttached": "",
	"install": "",
	"uninstall": "",
	"installed": "",
	"residentialAccountPublishAlert": "",
	"reset": "",
	"showLess": "",
	"residentialAccountAccess": "",
	"taskChangeStatus": "",
	"taskChangeStatusSubject": "",
	"residentialAccountSettingsShowShop": "",
	"residentialAccountSettingsShowShopSublabel": "",
	"residentialAccountSettingsShowShopDocuments": "",
	"residentialAccountSettingsShowShopDocumentsSublabel": "",
	"residentialAccountSettingsShowResidentialDocuments": "",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "",
	"redirectUrl": "",
	"contactSeller": "",
	"importFromCatalog": "",
	"reject": "",
	"rejected": "",
	"addon:project:flatfinder": "",
	"blockComplaints": "",
	"unblockComplaints": "",
	"complaintsBlocked": "",
	"taskStatusChangeToUndone": "",
	"taskStatusChangeToDone": "",
	"taskStatusChangeToRejected": "",
	"attachmentsSigningUploadWarning": "",
	"addonIsIncludedIn": "",
	"tourHowToEmbedFlatfinder": "",
	"helpers:roleManager": "",
	"helpers:roleSigningAuthority": "",
	"helpers:roleSalesman": "",
	"helpers:roleShopManager": "",
	"helpers:roleShopSigningAuthority": "",
	"helpers:roleShopSalesman": "",
	"salesmanShopVisibility": "",
	"productBundleContains": "",
	"productBundle": "",
	"rent": "",
	"saleType": "",
	"actionLabel:export": "",
	"actionLabel:integration": "",
	"action": "",
	"helpers:productBundle": "",
	"office": "",
	"integrationDpaRecommendation": "",
	"ordinaryProductPrice": "",
	"helpers:shopPricingMethodTitle:add": "",
	"helpers:shopPricingMethodDescription:add": "",
	"helpers:shopPricingMethodTitle:diff": "",
	"helpers:shopPricingMethodDescription:diff": "",
	"selectMethod": "",
	"priceCalculation": "",
	"unitNumber": "",
	"productUpdateAffectedProducts": "",
	"upcomingLabel": "",
	"hide": "",
	"residentialAdditionalFieldsLabel": "",
	"link": "",
	"loggingIn": "",
	"setDueDates": "",
	"complaintDescription": "",
	"helpers:managementOperationMaintenance": "",
	"helpers:roleComplaintManager": "",
	"residentialAccountLoginCondition": "",
	"noComplaints": "",
	"complaintDisclaimerConsent": "",
	"complaintDisclaimerEdit": "",
	"fdvControlLabel": "",
	"fdvControlSublabel": "",
	"shopEditDueAtUnitNotSold": "",
	"confirmed": "",
	"control": "",
	"noMembers": "",
	"complaintRoleMissing": "",
	"folder": "",
	"helpers:complaintsList": "",
	"completeTask": "",
	"changeStatus": "",
	"confirmRequest": "",
	"rejectRequest": "",
	"setTaskToUndone": "",
	"cannotEditMultipleResidentialFieldsSameSaleType": "",
	"noContacts": "",
	"filterEmptyState": "",
	"invoice": "",
	"receiver": "",
	"addon:project:presentation:sublabel": "",
	"addon:project:bid:sublabel": "",
	"addon:project:purchase:sublabel": "",
	"addon:project:shop:sublabel": "",
	"addon:project:shop-purchase:sublabel": "",
	"addon:project:residential-account:sublabel": "",
	"addon:project:residential-account": "",
	"billing": "",
	"featuredResidentialPropertyHelper": "",
	"viewProject": "",
	"unitSelectorIsEntry": "",
	"autocompleteAddressAlert": "",
	"externalFileDisclaimer": "",
	"getMarkerPosition": "",
	"createResource": "",
	"taskCommentsReceiverInfo": "",
	"createdBy": "",
	"openhouseEmptyState": "",
	"documentsEmptyState": "",
	"startShops": "",
	"noUnitTemplates": "",
	"unitTemplatesEmptyState": "",
	"noApplicantShopContractTooltip": "",
	"noUsers": "",
	"usersEmptyState": "",
	"cannotCreateMoreX": "",
	"cannotDelete": "",
	"noX": "",
	"thisFieldIsFetchedFromX": "",
	"addSecondaryColor": "",
	"primaryColor": "",
	"secondaryColor": "",
	"unitMustBeSoldToSetOwner": "",
	"owner": "",
	"tableFooterTip": "",
	"sendMessage": "",
	"mediaDescriptionPlaceholder": "",
	"taskTypeDefault": "",
	"taskTypeComplaint": "",
	"downloadX": "",
	"noUnsavedChanges": "",
	"installMore": "",
	"propertyType:four-person-home": "",
	"addCompanyInfo": "",
	"shopTotalLabel": "Totalpris for tilvalg",
	"addDesiredAmount": "",
	"creationDate": "",
	"shop-partialConfirmationMessage": "Vi har modtaget din signerede tilvalgsbestilling. Når modparten har signeret denne, vil du modtage en bekræftelse. Vedlagt finder du en oversigt over din bestilling.",
	"shop-partialAcceptMessage": "Din tilvalgskontrakt er accepteret",
	"shop-partialDeclinedMessage": "Din tilvalgskontrakt er afvist",
	"customConfirmationMessage": "Vi har modtaget din kontrakt, og du får svar fra os så hurtigt som muligt",
	"customAcceptMessage": "Din kontrakt er accepteret",
	"customDeclinedMessage": "Din kontrakt er afvist"
}